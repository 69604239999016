<template>
	<div style="padding: 20px 10px 0 10px;overflow-y: scroll;">
		<p style="font-size: 18px;font-weight: 500;">{{testPaperInfo.name}}</p>
		<div style="display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: space-between;margin-top: 10px">
			<p style="font-size: 12px;">总分:{{testPaperInfo.score}}</p>
			<p style="font-size: 12px;">时长:{{testPaperInfo.suggestTime}}分钟</p>
		</div>
		<el-divider></el-divider>
    <div v-for="(item,index) in allQuestionArr" :key="index">
      <div style="font-size: 12px;margin-bottom: 10px;margin-top: 10px;color: #34495e">
        {{item.name}}
      </div>
      <div>
        <!-- <a :href="'#' + item" v-for="item in allNumberArr" :key="item"> -->
        <a @click="clickanchor(`#q${item}`)" v-for="item in item.questionOrder" :key="item">
          <el-button size="mini" type="infor" style="margin-left: 4.5px;margin-top: 4.5px;" class="subjetButton">
            {{item}}
          </el-button>
        </a>
      </div>
    </div>

		<div class="headerSty">
			<div class="headerTime">
				<p>
					剩余时间:
				</p>
				<p>
					{{timeShow}}
					<!-- 30 -->
				</p>
			</div>
			<div style="margin-top: 20px;width: 100%;text-align:center;">
				<el-button size="mini" type="primary" @click="submitSubject">提交试卷</el-button>
				<br>
				<br>
				<el-button size="mini" type="danger" @click="cancelSubmit">取消答题</el-button>

			</div>
		</div>
	</div>
</template>

<script>
export default {
		props: {
			suggestTime: {
				type: Number,
				default: 60
			},
			testPaperInfo: {
				type: Object,
				default: () => ({})
			},
			allNumberArr: {
				type: Array,
				default: () => []
			},
			answerArr: {
				type: Array,
				default: () => []
			},
      allQuestionArr: {
        type: Array,
        default: () => []
      }
		},
		watch: {
			answerArr: {
				handler: function(n, o) {
					let idArr = [];
					this.answerArr.forEach(item => {
						idArr.push(item.id)
					})
					let butonArr = document.getElementsByClassName("subjetButton");
					butonArr.forEach((item) => {
            // console.log(item)
						let innerHTMLValue = item.getElementsByTagName("span")[0].innerHTML
						if (idArr.indexOf(parseInt(innerHTMLValue)) != -1) {
							item.classList.remove("el-button--infor"); // 设置
							item.classList.add("el-button--success"); // 设置
						} else {
							item.classList.remove("el-button--success"); // 设置
							item.classList.add("el-button--infor"); // 设置
						}
						// if(this.answerArr.indexOf(innerHTMLValue))
					})
				},
				deep: true
			},
			allSecond: {
				handler: function(n, o) {
					if (this.allSecond >= 0) {
						this.timeShow = this.formatTime(this.allSecond)
					} else {
						clearInterval(this.lock)
						this.$alert('考试时间到', '提示', {
							showClose: false,
							closeOnClickModal: false,
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push({
									name: "examinationRecord"
								})
							}
						});
					}
				}
			},
			suggestTime: {
				handler: {
					handler: function(n, o) {
						if (this.suggestTime) {
							this.startTime()
						}
					}
				}
			}
		},
		data() {
			return {
				allSubject: [],
				time: 60,
				allSecond: null,
				timeShow: "",
				lock: null
			}
		},
		methods: {
			isAllWrite(){
				let isOver = true
				let butonArr = document.getElementsByClassName("subjetButton");
				butonArr.forEach((item) => {
					let innerHTMLValue = item.getElementsByTagName("span")[0].innerHTML
					// console.log(item.classList)
					item.classList.forEach(item1 => {
						if(item1 == "el-button--infor"){
							isOver = false
						}
					})
				})
				return isOver
			},
			clickanchor(idName) {
				this.$emit("changeMaodian",idName);
				// console.log("123")
				// console.log(idName);
				// document.querySelector(idName).scrollIntoView({
				// 	behavior: "smooth"
				// });
			},
			formatSubject() {
				// this.
			},
			submitSubject() {
				this.isAllWrite();
				if(this.isAllWrite()){
					this.$emit("submitPaper", parseInt(this.allSecond / 60))
				}
				else{
					this.$message.warning("请回答完题目")
				}

				// this.$emit("submitPaper", parseInt(this.allSecond / 60))
			},
			cancelSubmit(){
				this.$confirm('确定退出，取消答题?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// this.$router.go(-1)
         			 window.close()
				}).catch(() => {

				});
			},
			startTime() {
				this.time = Number(this.suggestTime)
				// console.log(this.suggestTime)
				this.allSecond = Number(this.time) * 60;
				this.lock = window.setInterval(() => {
					this.allSecond--;
				}, 1000)
			},
			formatTime(time) {
				let sec = this.addZero(time % 60);
				let min = this.addZero(parseInt(time / 60));
				return `${min}:${sec}`
			},
			addZero(num) {
				if (num == 0) {
					return "00"
				} else if (num < 10) {
					return "0" + num
				} else {
					return num
				}
			}
		},
		beforeDestroy() {
			clearInterval(this.lock)
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.el-divider--horizontal {
		// margin: 0;
		margin-top: 20px;
		margin-bottom: 10px;
		// padding-bottom: 10px;
	}

	.headerSty {
		// display: flex;
		// flex-wrap: nowrap;
		// flex-direction: row;
		// justify-content: space-between;
		margin-top: 30px;

		.headerTime {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: center;
			// text-align: center;

			p:first-child {
				font-size: 20px;
			}

			p:last-child {
				font-size: 20px;
				color: red;
				font-weight: 900;
			}
		}
	}
</style>
