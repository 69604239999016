<template>
	<div>
		<div>
			<el-card class="box-card">
				<div slot="header" class="subjectTitle">
					<div style="display: flex;">
						<span style="font-size: 17px;">{{subjectId}}.</span>
						<div v-html="questionBody.title" style="font-size: 17px;"></div>
					</div>
				</div>
				<div style="margin-top: 10px;">
					<el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="textarea" :disabled="isDis">
					</el-input>
				</div>
				<div v-if="haveAnswer">
					<p class="content" v-if="!(questionBody.questionType == 3 || questionBody.questionType == 4)">
						<span>标准答案:</span>{{questionBody.correct}}</p>
					<p class="content"><span>标准答案:</span><span v-html="questionBody.correct" class="classHtml"></span></p>
					<div style="display: flex;">
						<span class="content"><span>结果:</span></span>
						<el-tag size="mini" v-if="questionBody.answerInfo.doRight" type="success"
							style="margin-top: 20px;">
							正确</el-tag>
						<el-tag size="mini" v-if="!questionBody.answerInfo.doRight" type="danger"
							style="margin-top: 20px;">
							错误</el-tag>
					</div>
					<p class="content"><span>得分:</span>{{questionBody.answerInfo.score}} / {{questionBody.score}}</p>
					<p class="content">
						<span>得分率:</span>{{parseInt(questionBody.answerInfo.score / questionBody.score) * 100}}%
					</p>
					<div style="display: flex;">
						<p class="content"><span>难度:</span></p>
						<el-rate v-model="questionBody.difficult" style="margin-top: 20px;" disabled=""></el-rate>
					</div>
					<p class="content"><span>解析:</span><span v-html="questionBody.analyze"></span></p>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			subjectId: {
				type: Number,
				default: 1
			},
			isDis: {
				type: Boolean,
				default: false
			},
			questionBody: {
				type: Object,
				default: () => ({})
			},
			haveAnswer: {
				type: Boolean,
				default: false
			},
			chooseItem: {
				type: String,
				default: ""
			}
		},
		mounted() {
			// console.log(this.questionBody)

			if (this.haveAnswer) {
				// console.log(this.questionBody)
				this.textarea = this.questionBody.answerInfo.content

				// this.questionBody.items.forEach((item, index) => {
				// 	this.textarea = item.content
				// })
			}
		},
		watch: {
			textarea: {
				handler: function(n, o) {
					if (this.haveAnswer) {

					} else {
						// console.log(this.textarea)
						if (this.textarea) {
							// this.$emit("changed", this.subjectId)
							this.$emit("changed", {
								id: this.subjectId,
								name: this.radio,
								questionId: this.questionBody.id,
								content: this.textarea,
								contentArray: [],
								itemOrder: this.subjectId
							})
						} else {
							this.$emit("unchanged", {
								id: this.subjectId,
								name: this.textarea
							})
						}
					}
				},
				deep: true
			}
		},
		data() {
			return {
				textarea: ""
			}
		}
	}
</script>

<style lang="scss" scoped>
	.subjectName {
		display: flex;

		p {
			font-size: 16px;
		}
	}


	.content {
		font-size: 14px;
		margin-top: 20px;
		color: #808080;
		display: flex;



		span {
			font-weight: 600;
			color: #000;
			line-height: 20px;
		}

		.classHtml{
			color: #808080;
		}
	}
</style>
