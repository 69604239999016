import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/icons' // icon
import { getJSON, post, patch, put, getDS, DSpost } from '@/utils/request.js'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import echarts from 'echarts'

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
Vue.use(ElementUI);


// Vue.prototype.$getDS = getDS
// Vue.prototype.$post = post
// Vue.prototype.$DSpost = DSpost
// Vue.prototype.$getJSON = getJSON
// Vue.prototype.$patch = patch
// Vue.prototype.$put = put

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
