<template>
  <div class="page">
    <div class="bottomSty">
      <div v-for="item in bottomInfo" :key="item.title" style="width: 25%;text-align: center;">

        <p class="titleSty">{{ item.title }}</p>
        <div class="childSty">
          <p v-for="item1 in item.childArr" :key="item1.name" @click="clickBottomItem(item1.name)">
            {{ item1.name }}
          </p>
        </div>

      </div>
    </div>
    <!--    https://beian.miit.gov.cn/#/Integrated/index-->
    <div class="footerContext">
      <div @click="ToOtherPage"></div>
      <a href="https://beian.miit.gov.cn/" target="_blank">网站备案编号： 沪ICP备20006544号-1</a>
      <br/>
      <br/>
      <div>Copyright © 2019 ~ 2024, WANCODE Education and Technology Co., Ltd.（Shanghai）All Rights Reserved.上海玩扣教育科技有限公司 提供技术支持</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bottomInfo: [{
        title: "关于我们",
        titleLink: "",
        childArr: [
        //     {
        //   name: "公司简介",
        //   childLink: ""
        // },
          {
          name: "教师版",
          childLink: ""
        }, {
          name: "加入我们",
          childLink: ""
        }]
      },

        {
          title: "联系我们",
          titleLink: "",
          childArr: [{
            name: "联系我们",
            childLink: ""
          }, {
            name: "课程咨询",
            childLink: ""
          }]
        },
        {
          title: "其他服务",
          titleLink: "",
          childArr: [{
            name: "更多工具",
            childLink: ""
          },{
            name: "忘记密码",
            childLink: ""
          }
            // ,{
            //   name: "考试报名",
            //   childLink: ""
            // }
          ]
        },
        {
          title: "服务协议",
          titleLink: "",
          childArr: [{
            name: "用户协议",
            childLink: ""
          }, {
            name: "隐私协议",
            childLink: ""
          }, {
            name: "免责声明",
            childLink: ""
          }]
        },
      ]
    }
  },
  methods: {
    clickBottomItem(item) {
      this.$emit("clickBottomItem", item)
    },
    ToOtherPage(){

    }
  }
}
</script>

<style lang="scss" scoped>

.footerContext{
  width: 100%;
  height: auto;
  background-color: rgba(230, 230, 250, 0.8);
  text-align: center;
  padding: 20px 0;
}
.bottomSty {
  width: 100%;
  height: auto;
  background-color: rgba(230, 230, 250, 0.8);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  // text-align: center;

  .titleSty {
    font-size: 20px;
    line-height: 50px;
    font-weight: 800;
  }

  .childSty {
    p {
      font-size: 14px;
      line-height: 30px;
    }

    p:hover {
      cursor: pointer;
      color: #9932CC;
    }
  }

}
</style>
