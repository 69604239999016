<template>
	<div>
		<div class="headerSty">
			<div class="headerTime">
				<p>
					剩余时间:
				</p>
				<p>
					{{timeShow}}
				</p>
			</div>
			<div>
				<el-button size="mini" type="primary" @click="submitSubject">提交试卷</el-button>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		props: {
			suggestTime:{
				type:Number,
				default:60
			}
		},
		watch: {
			allSecond: {
				handler: function(n, o) {
					if (this.allSecond >= 0) {
						this.timeShow = this.formatTime(this.allSecond)
					} else {
						clearInterval(this.lock)
						this.$alert('考试时间到', '提示', {
							showClose: false,
							closeOnClickModal: false,
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push({
									name: "examinationRecord"
								})
							}
						});
						// console.log("完成")
					}
				}
			},
			suggestTime: {
				handler: {
					handler: function(n, o) {
						if(this.suggestTime){
							this.startTime()
						}
					}
				}
			}
		},
		data() {
			return {
				time: 60,
				allSecond: null,
				timeShow: "",
				lock: null
			}
		},
		mounted() {
			// this.startTime()
		},
		methods: {
			submitSubject() {
				// console.log(parseInt(this.allSecond/60))
				// clearInterval(this.lock)
				this.$emit("submitPaper",parseInt(this.allSecond/60))
			},
			startTime() {
				this.time = Number(this.suggestTime)
				// console.log(this.suggestTime)
				this.allSecond = Number(this.time) * 60;
				this.lock = window.setInterval(() => {
					this.allSecond--;
				}, 1000)
			},
			formatTime(time) {
				let sec = this.addZero(time % 60);
				let min = this.addZero(parseInt(time / 60));
				return `${min}:${sec}`
			},
			addZero(num) {
				if (num == 0) {
					return "00"
				} else if (num < 10) {
					return "0" + num
				} else {
					return num
				}
			}
		},
		beforeDestroy() {
			clearInterval(this.lock)
		}
	}
</script>

<style lang="scss" scoped>
	.headerSty {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		justify-content: space-between;

		.headerTime {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;

			p:first-child {
				font-size: 16px;
			}

			p:last-child {
				font-size: 20px;
				color: red;
				font-weight: 900;
			}
		}
	}
</style>
