<template>
	<div>
		<div>
			<el-card class="box-card">
				<div slot="header" class="subjectTitle">
					<div style="display: flex;" class="subjectName">
						<span style="font-size: 19px;">{{subjectId}}.</span>
						<div v-html="questionBody.title" style="font-size: 19px;"></div>
					</div>
				</div>
        <p   v-if="haveAnswer" class="content" style="padding-bottom: 10px"><span>我的答案:</span></p>
        <div>
          <editor ref="pyEditer" v-model="myAnswer" @init="editorInit" lang="python" theme="eclipse"
                  width="100%" height="400px" :options="{
					   enableBasicAutocompletion: true,
					   enableSnippets: true,
					   enableLiveAutocompletion: true,
					   tabSize:2,
					   fontSize:17,
					   showPrintMargin:false
					 }"></editor>
        </div>

				<div v-if="haveAnswer">
<!--					<p class="content"><span>标准答案:</span><span v-html="questionBody.correct"></span></p>-->
					<div style="display: flex;">
						<span class="content"><span>结果: </span></span>
						<el-tag size="mini" v-if="questionBody.answerInfo.doRight" type="success"
							style="margin-top: 20px;">
							正确</el-tag>
						<el-tag size="mini" v-if="!questionBody.answerInfo.doRight" type="danger"
							style="margin-top: 20px;">
							错误</el-tag>
					</div>
					<p class="content"><span>得分:  </span>{{questionBody.answerInfo.score}} / {{questionBody.score}}</p>
					<p class="content">
						<span>得分率:</span>60%
					</p>
					<div style="display: flex;">
						<p class="content"><span>难度:  </span></p>
						<el-rate v-model="questionBody.difficult" style="margin-top: 20px;" disabled=""></el-rate>
					</div>
          <div>
            <p class="content" style="padding-bottom: 10px"><span>参考答案:</span></p>
            <editor ref="pyEditer" v-model="content" @init="editorInit" lang="python" theme="eclipse"
                    width="100%" height="400px" :options="{
					   enableBasicAutocompletion: true,
					   enableSnippets: true,
					   enableLiveAutocompletion: true,
					   tabSize:2,
					   fontSize:17,
					   showPrintMargin:false
					 }"></editor>
          </div>
          <p class="content"><span>解析:</span><span v-html="questionBody.analyze"></span></p>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import glbJson from '../utils/global_variable.js'

	export default {
		// components:{monaco},
		components: {
			editor: require('vue2-ace-editor')
		},
		props: {
			subjectId: {
				type: Number,
				default: 1
			},
			isDis: {
				type: Boolean,
				default: false
			},
			questionBody: {
				type: Object,
				default: () => ({})
			},
			haveAnswer: {
				type: Boolean,
				default: false
			},
			chooseItem: {
				type: String,
				default: ""
			}
		},
		mounted() {
			if (this.haveAnswer) {
				// console.log(this.questionBody)
				// this.questionBody.items.forEach((item, index) => {
				// 	this.content = this.chooseItem
				// this.content = this.questionBody.answerInfo.content

				this.content = this.questionBody.correct
				this.myAnswer = this.questionBody.answerInfo.content
				// })
			}else{
				if(this.questionBody.subjectId ===1
						|| this.questionBody.subjectId ===2
						|| this.questionBody.subjectId ===3
						|| this.questionBody.subjectId ===4
						|| this.questionBody.subjectId ===9
						|| this.questionBody.subjectId ===10
						|| this.questionBody.subjectId ===51
						|| this.questionBody.subjectId ===404){
					this.myAnswer='Scratch编程题目前暂不支持代码上传，现只需自己电脑答题即可'
				}
			}
		},
		methods: {
			editorInit: function() {
				//初始化ace
				require("brace/ext/language_tools");
				require("brace/mode/python");
				require("brace/snippets/python");
				require("brace/theme/eclipse");
			}
		},
		watch: {
      myAnswer: {

        handler: function(n, o) {
          if (this.haveAnswer) {

          } else {
            if (this.myAnswer == "") {
              this.$emit("unchanged", {
                id: this.subjectId,
                name: this.myAnswer
              })
            } else {
              this.$emit("changed", {
                id: this.subjectId,
                name: this.radio,
                questionId: this.questionBody.id,
                content: this.myAnswer,
                contentArray: [],
                itemOrder: this.subjectId
              })
            }
          }
        },
        deep: true
      },
			content: {

				handler: function(n, o) {
					if (this.haveAnswer) {

					} else {
						// console.log(this.content)
						if (this.content == "") {
							this.$emit("unchanged", {
								id: this.subjectId,
								name: this.content
							})
						} else {
							this.$emit("changed", {
								id: this.subjectId,
								name: this.radio,
								questionId: this.questionBody.id,
								content: this.content,
								contentArray: [],
								itemOrder: this.subjectId
							})
						}
					}
				},
				deep: true
			}
		},
		data() {
			return {
				textarea: "",
				content: "",
				myAnswer: ""
			}
		}
	}
</script>

<style lang="scss" scoped>
	.subjectName {
		display: flex;
		-webkit-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
		line-height: 36px;
		p {
			font-size: 16px;
		}
	}


	.content {
		font-size: 14px;
		margin-top: 20px;
		color: #808080;
		display: flex;

		span {
			font-weight: 600;
			color: #000;
			line-height: 20px;
		}
	}
</style>
