<template>
  <div id="app">
    <testPaperDetails v-if="(path === '/testPaperDetails')"></testPaperDetails>
    <div v-show="!(path === '/testPaperDetails')">
      <homeTitle style="margin-top: 10px" :loginDialogShow="loginDialogShow" @clickLogin="clickLogin"></homeTitle>
      <router-view style="min-height: 100px;padding-bottom: 30px;"/>
      <homeBottom @clickBottomItem="clickBottomItem"></homeBottom>
      <!-- <button @click="clearSession">清除</button> -->
      <loginDialog :loginDialogShow="loginDialogShow" @closeDialog="closeDialog" @loginSuccess="loginSuccess">
      </loginDialog>
      <rightBlock style="position:fixed;top: 0;right: 0;height: 100%;z-index: 999;"></rightBlock>
    </div>

    <el-dialog title="版权与免责声明" :visible.sync="exemptionDialogVisible" width="40%" center>
      <p style="font-size:16px;margin: 0 auto;">本网站发布的所有作品， 均为上海玩扣教育科技有限公司原创或获得正版授权刊载或网友投稿的作品，
        未经本公司授权不得转载、摘编或利用其它方式使用上述作品。 已经本公司授权使用作品的，应在授权范围内使用，
        并注明“来源： 玩扣教育”。违反上述声明者， 本公司将追究其相关法律责任。</p>
      <p style="font-size:16px;margin: 0 auto;margin-top: 20px;">本公司在制作上述作品时，采用了部分来源网络的内容， 该部分内容并未标明出处导致原作者不详，
        因而本公司未能获取相应的授权。 本公司非常重视知识产权问题，
        尊重原作者的智力成果，如果本司的上述作品使用了您的版权内容， 请您及时与本公司联系，本公司将立即与您协商解决版权相关事宜。</p>
      <p style="font-size:16px;margin: 0 auto;margin-top: 20px;">邮箱：wancode@163.com</p>
      <p style="font-size:16px;margin: 0 auto;margin-top: 20px;text-align: right">上海玩扣教育科技有限公司</p>
      <p style="font-size:16px;margin: 0 auto;margin-top: 20px;text-align: right">2019年1月</p>
    </el-dialog>

    <el-dialog title="公司简介" :visible.sync="companyProfileVisible" width="40%" center>
      <p style="font-size:16px;margin: 0 auto;">上海玩扣教育科技有限公司，简称"玩扣"。</p>
      <p style="font-size:16px;margin: 0 auto;">玩扣是一款创新的少儿机器人编程教育品牌。</p>
      <p style="font-size:16px;margin: 0 auto;">我们的愿景，是让每一个孩子都学会编程，给孩子创造未来的力量。</p>
      <p style="font-size:16px;margin: 0 auto;">玩扣少儿编程教育，不只是少儿时一段暂时的经历，</p>
      <p style="font-size:16px;margin: 0 auto;">而是人工智能时代助人前行的动力；</p>
      <p style="font-size:16px;margin: 0 auto;">人工智能时代的到来，编程将成为未来世界的通用语言，是未来的沟通工具。</p>
      <p style="font-size:16px;margin: 0 auto;">以前，我们说孩子要与世界沟通，必须学会英语；</p>
      <p style="font-size:16px;margin: 0 auto;">在互联网和人工智能的今天和未来，孩子要与世界沟通，就必须学会与计算机.互联网.人工智能沟通的语言，这就是玩扣编程。</p>
    </el-dialog>
    <el-dialog title="教师版" :visible.sync="teacherVisible" width="40%" center>
      <el-button type="text" @click="open" style="font-size:16px;margin: 0 auto;text-align: center">开通教师版会员</el-button>
      <br>
      <el-button type="text" style="font-size:16px;margin: 0 auto;text-align: center">教师版体验账号:teacher</el-button>
      <br>
      <el-button type="text" style="font-size:16px;margin: 0 auto;text-align: center">教师版体验密码:teacher</el-button>
    </el-dialog>
    <el-dialog title="加入我们" :visible.sync="joinUsVisible" width="40%" center>
      <p style="font-size:16px;margin: 0 auto;">邮箱：wancode@163.com</p>
    </el-dialog>

    <el-dialog title="联系我们" :visible.sync="contactUsVisible" width="40%" center>
      <p style="font-size:16px;margin: 0 auto;">邮箱：wancode@163.com</p>
      <br>
      <p style="font-size:16px;margin: 0 auto;">投稿邮箱：wancode@163.com</p>
      <br>
      <p style="font-size:16px;margin: 0 auto;">联系地址：上海市徐汇区田林路487号</p>
    </el-dialog>
    <el-dialog title="课程咨询" :visible.sync="CourseConsultVisible" width="40%" center>
      <p style="font-size:16px;margin: 0 auto;">课程顾问QQ: 630691351</p>
      <br>
      <p style="font-size:16px;margin: 0 auto;">课程顾问VX: wan_code</p>
      <br>
      <p style="font-size:16px;margin: 0 auto;">编程QQ学习交流群: 602969887 (验证消息：wancode)</p>
    </el-dialog>
    <el-dialog title="忘记密码" :visible.sync="passwordProfileVisible" width="40%" center>
      <p style="font-size:16px;margin: 0 auto;">目前仅支持人工客服修改密码，请联系人工客服</p>
      <br>
      <p style="font-size:16px;margin: 0 auto;">QQ：630691351</p>
    </el-dialog>
  </div>

</template>
<script>
import homeTitle from "@/components/homeTitle.vue";
import homeBottom from "@/components/homeBottom.vue";
import loginDialog from "@/components/loginDialog.vue";
import rightBlock from "@/components/rightBlock.vue";
import testPaperDetails from "@/views/testPaperDetails/index.vue"

export default {
  components: {
    homeTitle,
    homeBottom,
    loginDialog,
    testPaperDetails,
    rightBlock
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
      // console.log(to.path)
    }
  },
  data() {
    return {
      loginDialogShow: false,
      path: "",
      exemptionDialogVisible: false,
      contactUsVisible: false,
      CourseConsultVisible: false,
      joinUsVisible: false,
      teacherVisible: false,
      companyProfileVisible: false,
      passwordProfileVisible: false
    };
  },
  mounted() {
    this.path = this.$route.path;

    window.showLogin = this.showDialog
    // if (localStorage.getItem("token")) {
    //   this.loginDialogShow = false
    // } else {
    //   this.loginDialogShow = true
    // }
  },
  methods: {
    clickBottomItem(item) {
      // console.log(item)
      if (item === "公司简介") {
        this.companyProfileVisible = true
      }
      if (item === "教师版") {
        this.teacherVisible = true
      }
      if (item === "联系我们") {
        this.contactUsVisible = true
      }
      if (item === "课程咨询") {
        this.CourseConsultVisible = true
      }
      if (item === "免责声明") {
        this.exemptionDialogVisible = true
      }
      if (item === "考试报名") {
        window.open("https://www.qceit.org.cn/bos/default.html");
      }
      if (item === "忘记密码") {
        this.passwordProfileVisible = true
      }
      if (item === "加入我们") {
        this.joinUsVisible = true
      } else if (item === "隐私协议") {
        window.open("/programmingPrivacy.html");                 //在另外新建窗口中打开窗口
      } else if (item === "用户协议") {
        window.open("/serviceAgreement.html");                 //在另外新建窗口中打开窗口
      }
    },
    open(){
      this.$router.push({ name: 'selfVIP', params: { teacherPrise: "1" }});
      this.teacherVisible = false
      window.scrollTo(0, 0);
    },
    clickLogin() {
      this.loginDialogShow = true
    },
    // clearSession() {
    //   localStorage.setItem("token", "");
    // },
    closeDialog() {
      this.isHidden = true;
      setTimeout(() => {
        this.loginDialogShow = false
      })
    },
    showDialog() {
      this.loginDialogShow = true
      // console.log(this.loginDialogShow)
    },
    loginSuccess() {
      // this.$refs.newestNotic.getInfo()
      // this.$refs.testPaperCenter.getInfo()
    }
  }
};
</script>

<style lang="scss">

/*.el-dialog__body {*/
/*  height: 55vh;*/
/*  overflow: auto;*/
/*}*/

.page {
  width: 1280px;
  margin: 0 auto;
  text-align: left;
  //padding: 0 100px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

body,
button,
input,
select,
textarea {
  font: 12px/1.5tahoma, arial, \5b8b\4f53;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

code,
kbd,
pre,
samp {
  font-family: couriernew, courier, monospace;
}

small {
  font-size: 12px;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

legend {
  color: #000;
}

fieldset,
img {
  border: 0;
}

button,
input,
select,
textarea {
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //min-width: 1480px;
  background-color: #F5F5F5;
  //overflow: hidden;
}
</style>
