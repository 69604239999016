<template>
  <div class="page" v-loading="loading">
    <el-container style="height: 100vh;padding: 30px 0;border-bottom: 1px solid #cccccc;">
      <el-aside width="250px" style="background-color: rgb(238, 241, 246);padding-bottom: 10px;">
        <asideLeft :testPaperInfo="testPaperInfo"  :answerArr="answerArr" :allQuestionArr="allQuestionArr"
                   @submitPaper="submitPaper" :suggestTime="suggestTime" @changeMaodian="changeMaodian">
        </asideLeft>
      </el-aside>
      <el-container>
        <!-- <el-header> -->
        <!-- <headerDetails @submitPaper="submitPaper" :suggestTime="suggestTime"></headerDetails> -->
        <!-- </el-header> -->
        <el-main>
          <mainDetails ref="mainDetails" @isAnswerArr="isAnswerArr" :allQuestionArr="allQuestionArr" :paperTitle="paperTitle"
                       :maodian="maodian"></mainDetails>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import asideLeft from "./components/asideLeft.vue"
import headerDetails from "./components/headerDetails.vue"
import mainDetails from "./components/mainDetails.vue"
import {
  getPaperDetails
} from "@/api/index.js"


export default {
  components: {
    asideLeft,
    headerDetails,
    mainDetails
  },
  data() {
    return {
      allNumberArr: [],
      answerArr: [],
      allQuestionArr: [],
      paperTitle: '',
      testPaperId: null,
      testPaperInfo: {},
      suggestTime: null,
      loading: true,
      maodian: '#1'
    }
  },
  created() {
    // this.$nextTick(() => {
    // 	// 禁用右键
    // 	document.oncontextmenu = new Function("event.returnValue=false");
    // 	// 禁用选择
    // 	document.onselectstart = new Function("event.returnValue=false");
    // });
  },
  mounted() {
    // if(this.testPaperId){
    this.testPaperId = this.$route.query.id;
    // console.log(this.testPaperId)
    this.getInfo(this.testPaperId)
  },
  methods: {
    changeMaodian(idName) {
      this.maodian = idName
    },
    isAnswerArr(item) {
      this.answerArr = item
    },
    getInfo(id) {
      this.loading = true
      getPaperDetails(id).then(res => {
        // console.log(res)
        if (res.code === "0000") {
          this.testPaperInfo = res.data
          this.paperTitle = res.data.name
          this.allQuestionArr = res.data.titleItems
          // 		console.log(this.questionBody.enterNum)

          this.suggestTime = res.data.suggestTime
          let numberArr = []
          res.data.titleItems.forEach(item => {
            // console.log(item)
            if (item.questionItems) {
              item.questionItems.forEach(item1 => {
                numberArr.push(item1.itemOrder)
              })
            }

          })
          this.allNumberArr = numberArr
          this.loading = false
        }
      }).catch(() => {
        this.loading = false

        if (localStorage.getItem("token")) {
          // this.loginDialogShow = false
        } else {
          showLogin();
        }

        this.$router.push({
          path: '/'
        })

        // this.loading = false
        // this.$confirm('此作品，非VIP用户不可操作', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '开通会员',
        //     type: 'warning'
        // }).then(() => {
        //     window.close()
        // }).catch(() => {
        //     this.$router.push({
        //         path: '/selfVIP'
        //     })
        // });
      });
    },
    submitPaper(time) {
      this.$refs.mainDetails.submitSubject(this.testPaperInfo.id, time)
    }
  }
};
</script>

<style lang="scss" scoped>
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

.el-main {
  background-color: #FFFFFF !important;
}
</style>
