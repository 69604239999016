<template>
  <div style="width: 100%;background:#ffffff">
    <!--    <div class="logoSty">-->
    <!--      <p class="logoP">青少年等级考试模拟考试平台</p>-->
    <!--    </div>-->
    <el-menu style="width:1280px;margin:0px auto;overflow: hidden;" mode="horizontal" text-color="#404040"
             active-text-color="#4c98c9">
        <el-menu-item index="0"  >
          <span v-if="!titleShow" style="width: 360px;font-size: 20px;text-align: left;letter-spacing:1px;margin-right: 100px" >CODE 青少年编程考试模拟教学平台</span>
          <span v-if="titleShow" style="width: 360px;font-size: 20px;text-align: left;letter-spacing:1px;margin-right: 210px">CODE 青少年编程考试模拟教学平台</span>
        </el-menu-item>
      <el-menu-item index="12" active-text-color="#ffd04b">
      </el-menu-item>
      <el-menu-item index="1" active-text-color="#ffd04b" @click="toHome()">首页
      </el-menu-item>
      <el-menu-item index="2" @click="toPaperList()">试卷中心</el-menu-item>
      <el-menu-item index="3" @click="toExaminationRecord()">考试记录</el-menu-item>
      <el-menu-item index="13" @click="toWrongBook()">错题本</el-menu-item>
      <el-menu-item index="4" @click="toQuestionBank()">题库答题</el-menu-item>
      <el-menu-item index="6" v-if="!titleShow" @click="toSelfVIP()" ><i class="el-icon-trophy"></i>开通会员</el-menu-item>
      <el-menu-item index="5"  v-if="!titleShow" @click="toTeacherManage()">教学中心</el-menu-item>
      <el-menu-item index="20" v-if="titleShow" @click="toMyWork()">我的作业</el-menu-item>
<!--      <el-submenu index="20" v-if="titleShow">-->
<!--        <template slot="title">我的作业</template>-->
<!--        <el-menu-item index="20-1" @click="toMyWork()">未完成</el-menu-item>-->
<!--        <el-menu-item index="20-2" @click="toMyWork()">已完成</el-menu-item>-->
<!--      </el-submenu>-->
<!--      <el-menu-item index="7" v-if="!titleShow" @click="toCourseSelectionCenter()">选课中心</el-menu-item>-->
<!--      <el-menu-item index="8" v-if="!titleShow"><a href="https://wancode.taobao.com" style="text-decoration: none" target="_blank">淘宝商城</a></el-menu-item>-->
      <el-menu-item index="9"  @click="toNotice()">公告</el-menu-item>
      <!--            <el-menu-item index="6" style="font-size: 16px" @click="toWrongBook()">下载中心</el-menu-item>-->
      <el-menu-item index="10">
        <div class="iconSty" v-if="haveToken">
          <el-popover placement="bottom" trigger="click">
            <template #reference>
              <el-image style="width: 36px; height: 36px;margin-top: -12px;margin-left: 30px;"
                        :src="iconUrl"
                        fit="fill"></el-image>
            </template>
            <div style="text-align: center;">
              <p style="line-height: 30px;height: 30px;">
                <router-link to="/selfInfo" style="text-decoration: none">个人中心</router-link>
              </p>
              <!--                            <p style="line-height: 30px;height: 30px;">消息中心</p>-->
              <el-divider style="margin-top: 10px;margin-bottom: 10px;"></el-divider>
              <p style="line-height: 30px;height: 30px;cursor:pointer" @click="logoutSys()">
                <router-link to="/" style="text-decoration: none">退出</router-link>
              </p>
            </div>
          </el-popover>
        </div>
        <div v-if="!haveToken">
          <el-button type="warning" size="mini"
                     @click="login()">登录/注册
          </el-button>
        </div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {logoutSystem, homeTitle, homePaper} from "@/api/index.js"

export default {
  data() {
    return {
      url: require('@/assets/logo.png'),
      iconUrl: require('@/assets/boy.png'),
      haveToken: false,
      titleShow: false,
      title: "青少年编程考试模拟教学平台"
    }
  },
  watch: {
    loginDialogShow: {
      handler: function (n, o) {
        this.getToken()
      }
    }
  },
  props: ["loginDialogShow"],
  mounted() {
    this.getToken()
    // this.getTitle()
  },
  methods: {
    login() {
      this.$emit("clickLogin")
    },
    getToken() {
      if (localStorage.getItem("token")) {
        this.haveToken = true
        if ("2" === localStorage.getItem("sex")) {
          this.iconUrl = require('@/assets/girl.png')
        } else {
          this.iconUrl = require('@/assets/boy.png')
        }
        if (localStorage.getItem("agentFlag") === '1') {
          if (localStorage.getItem("teacherFlag") === '0') {
            this.titleShow = true
          }
        }

      } else {
        this.haveToken = false
      }


    },
    getTitle() {
      this.loading = true;
      homeTitle().then(res => {
        if (res.code === "0000") {
          this.loading = false;
        }
      })
    },
    toHome() {
      this.$router.push({name: 'homePage'})
      window.scrollTo(0, 0)
    },
    toPaperList() {
      this.$router.push({name: 'testPaperCenter'})
      window.scrollTo(0, 0)
    },
    toExaminationRecord() {
      this.$router.push({name: 'examinationRecord'})
      window.scrollTo(0, 0)
    },
    toWrongBook() {
      this.$router.push({name: 'wrongBook'})
      window.scrollTo(0, 0)
    },
    toQuestionBank() {
      this.$router.push({name: 'questionBank'})
      window.scrollTo(0, 0)
    },
    toMyWork() {
      this.$router.push({name: 'myWork'})
      window.scrollTo(0, 0)
    },
    toTeacherManage() {
      this.$router.push({name: 'teacherManage'})
      window.scrollTo(0, 0)
    },
    toSelfVIP() {
      this.$router.push({name: 'selfVIP'})
      window.scrollTo(0, 0)
    },
    toCourseSelectionCenter() {
      this.$router.push({name: 'courseSelectionCenter'})
      window.scrollTo(0, 0)
    },
    toShoppingMall() {
      this.$router.push({name: 'shoppingMall'})
      window.scrollTo(0, 0)
    },
    toNotice() {
      this.$router.push({name: 'notice'})
      window.scrollTo(0, 0)
    },
    logoutSys() {
      logoutSystem().then(res => {
        if (res.code === "0000") {
          this.$message.success("成功退出")
          this.iconUrl = ''
          localStorage.setItem("token", "");
          localStorage.removeItem("userId")
          localStorage.removeItem("userVipType")
          localStorage.removeItem("userId")
          localStorage.removeItem("username")
          localStorage.removeItem("agentFlag")
          localStorage.removeItem("teacherFlag")
          this.haveToken = false
        }
      })
    }
  }
}
</script>

<style>
  .el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
    min-width: 120px!important;
  }
</style>
<style lang="scss" scoped>

/* 导航栏的间距等样式 */
.el-menu-item {
  padding: 0 13px;
  font-size: 16px;
}

.titleSty {
  overflow: hidden;
  width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;

  .logoSty {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;

    .logoP {
      font-size: 24px;
      font-weight: 500;
      height: 60px;
      line-height: 60px;
      text-shadow: 10px 10px 5px #CCCCCC;
    }
  }


  .nav {
    height: 50px;
    display: flex;
    justify-content: space-between;

    a {
      margin-left: 30px;
      box-sizing: border-box;
      height: 50px;
      line-height: 50px;
      font-weight: bold;
      color: #2c3e50;
      text-decoration: none;

      &.router-link-exact-active {
        color: #42b983;
        border-bottom: 5px solid #42b983;
      }
    }

    .iconSty {
      height: 60px;

      a {
        //margin-left: 10px;
        box-sizing: border-box;
        height: 60px;
        line-height: 60px;
        font-weight: bold;
        color: #2c3e50;
        text-decoration: none;
        // &.router-link-exact-active {
        // 	color: #42b983;
        // 	border-bottom: 5px solid #42b983;
        // }
      }
    }
  }

}
</style>
