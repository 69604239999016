<template>
	<div>
		<el-dialog :visible.sync="loginDialog" :show-close="false" class="login-container" width="500px">
			<el-tabs v-model="activeName" @tab-click="handleClick" stretch>
				<el-tab-pane label="登录" name="login">
					<particle-effect-button :hidden="isHidden" color="LightPink" :duration="800" type="circle"
						draw-style="fill">
						<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form"
							auto-complete="on" label-position="left">
							<div class="title-container">
								<h3 class="title">登录</h3>
							</div>

							<el-form-item prop="username">
								<div class="svg-input" style="display: flex;">
									<i class="el-icon-user svg-container"></i>
									<el-input ref="username" v-model="loginForm.username" placeholder="请输入登录账号"
										name="username" type="text" tabindex="1" auto-complete="on"></el-input>
								</div>
							</el-form-item>
							<el-form-item prop="password">
								<div style="display: flex;">
									<i class="el-icon-lock svg-container"></i>

									<el-input :key="passwordType" ref="password" v-model="loginForm.password"
										:type="passwordType" placeholder="请输入密码" name="password" tabindex="2"
										auto-complete="on" @keyup.enter.native="handleLogin">
									</el-input>
									<i :class="passwordType === 'password' ? 'el-icon-view svg-container' : 'el-icon-view svg-container'"
										@click="showPwd"></i>
								</div>

							</el-form-item>

							<el-button :loading="loading" type="primary" style="width: 458px;"
								@click.native.prevent="handleLogin">登录</el-button>
						</el-form>
					</particle-effect-button>
				</el-tab-pane>
				<el-tab-pane label="注册" name="register">
					<particle-effect-button :hidden="isHidden" color="LightPink" :duration="800" type="circle"
						draw-style="fill">
						<el-form ref="registerForm" :model="registerForm" :rules="registerRules" class="login-form"
							auto-complete="on" label-position="left">
							<div class="title-container">
								<h3 class="title">注册</h3>
							</div>

							<el-form-item prop="username">
								<div class="svg-input" style="display: flex;">
									<i class="el-icon-user svg-container"></i>
									<el-input ref="username" v-model="registerForm.username" placeholder="请输入手机号"
										name="username" type="text" tabindex="1" auto-complete="on"></el-input>
								</div>
							</el-form-item>
							<el-form-item prop="password">
								<div style="display: flex;">
									<i class="el-icon-lock svg-container"></i>

									<el-input :key="passwordType" ref="password" v-model="registerForm.password" minlength="4"
										:type="passwordType" placeholder="请输入密码" name="password" tabindex="2"
										auto-complete="on" @keyup.enter.native="register">
									</el-input>
									<i :class="passwordType === 'password' ? 'el-icon-view svg-container' : 'el-icon-view svg-container'"
										@click="showPwd"></i>
								</div>
							</el-form-item>

							<div style="display: flex;flex-wrap: nowrap;justify-content: space-between;">
								<el-input ref="codeInput" v-model="codeValue" placeholder="请输入验证码" type="text"
									style="border: 1px solid rgba(255, 255, 255, 0.1);background: rgba(0, 0, 0, 0.1);border-radius: 5px;color: #454545;margin-left: 0px;height: 38px;width: 330px;">
								</el-input>
								<div class="code" @click="refreshCode">
									<inentify :fresh="flag" @makedCode="getMakedCode"></inentify>
								</div>
							</div>

							<el-button :loading="loading" type="primary" style="width: 458px;margin-top: 20px;"
								@click.native.prevent="register">注册</el-button>
						</el-form>
					</particle-effect-button>
				</el-tab-pane>
			</el-tabs>

		</el-dialog>

		<el-dialog>

		</el-dialog>
	</div>
</template>

<script>
	import ParticleEffectButton from 'vue-particle-effect-button'
	import inentify from "./inentify.vue"
	import {
		submitPaperAnswer
	} from "@/api/index.js"

	import {
		login,
		register
	} from '@/api/index.js'
	export default {
		props: ["loginDialogShow"],
		components: {
			ParticleEffectButton,
			inentify
		},
		watch: {
			loginDialogShow: {
				handler: function(n, o) {
					this.loginDialog = this.loginDialogShow;
				}
			},
			loginDialog: {
				handler: function(n, o) {
					if (this.loginDialog) {} else {
						this.$emit("closeDialog");
					}
				}
			}
		},
		mounted() {
			this.flag = !this.flag;
		},

		data() {
			// const validateUsername = (rule, value, callback) => {
			// 	if (value.length < 3) {
			// 		callback(new Error("请输入正确的用户名"))
			// 	} else {
			// 		callback();
			// 	}
			// };
			const validatePassword = (rule, value, callback) => {
				if (value.length < 3) {
					callback(new Error("请输入正确的密码"))
				} else {
					callback();
				}
			};
			var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
				if (!value) {
					return callback(new Error('手机号码不能为空'))
				}
				setTimeout(() => {
					// Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
					// 所以在前面加了一个+实现隐式转换

					if (!Number.isInteger(+value)) {
						callback(new Error('请输入手机号'))
					} else {
						if (phoneReg.test(value)) {
							callback()
						} else {
							callback(new Error('手机号码格式不正确'))
						}
					}
				}, 100)
			};

			return {
				codeValue: "",
				flag: true, //该值变化，就会触发刷新
				code: '', //刷新后的验证码
				loginDialog: false,
				registerDialog: false,
				isHidden: false,
				loginForm: {
					username: "",
					password: ""
				},
				registerForm: {
					username: "",
					password: ""
				},
				loginRules: {
					username: [{
						required: true,
						trigger: "blur",
						// validator: checkPhone
					}],
					password: [{
						required: true,
						trigger: "blur",
						validator: validatePassword
					}]
				},
				registerRules: {
					username: [{
						required: true,
						trigger: "blur",
						validator: checkPhone
					}],
					password: [{
						required: true,
						trigger: "blur",
						validator: validatePassword
					}]
				},
				loading: false,
				passwordType: "password",
				activeName: 'login'

				// redirect: undefined
			};
		},
		methods: {
			// 切换验证码
			refreshCode() {
				this.flag = !this.flag;
			},
			getMakedCode(code) {
				this.code = code;
				// console.log('getMakedCode:', this.code);
			},
			handleClick(tab, event) {
				// console.log(tab, event);
				this.refreshCode()
			},
			showPwd() {
				if (this.passwordType === "password") {
					this.passwordType = ""
				} else {
					this.passwordType = "password"
				}
				let e = document.getElementsByClassName('el-icon-view')[0];
				this.passwordType === '' ? e.setAttribute('style', 'color: #409EFF') : e.setAttribute('style',
					'color: #c0c4cc');

				this.$nextTick(() => {
					this.$refs.password.focus()
				})
			},

			register() {
				if (this.codeValue === this.code) {
					this.flag = !this.flag
					this.loading = true;

					this.$refs.registerForm.validate(valid => {
						if (valid) {
							let param = {
								loginAccount: this.registerForm.username,
								password: this.registerForm.password
							}
							register(param).then(res => {
								if (res.code === "0000") {
									this.registerForm.username = "";
									this.registerForm.password = "";
									this.activeName = 'login'
									this.loading = false
									this.$message.success("注册成功")
								}
								this.loading = false
							}).catch(() => {
								this.loading = false;
							});
						}
					}).catch(() => {
						this.loading = false;
					});
				} else {
					this.$message.error("请输入正确的验证码")
				}
				this.loading = false;
			},
			// 登录
			handleLogin() {
				// this.$emit("loginSuccess")
				this.$refs.loginForm.validate(valid => {
					if (valid) {
						let param = {
							loginAccount: this.loginForm.username,
							password: this.loginForm.password
						}
						login(param).then(res => {
							if (res.code === "0000") {
								localStorage.setItem("token", res.data.token);
								localStorage.setItem("userVipType", res.data.userVipType);
								localStorage.setItem("userId", res.data.userId);
								localStorage.setItem("username", this.loginForm.username);
								localStorage.setItem("sex", res.data.sex)
								localStorage.setItem("agentFlag", res.data.agentFlag)
								localStorage.setItem("teacherFlag", res.data.teacherFlag)
								this.loading = true;
								this.isHidden = true;

								setTimeout(() => {
									this.loginDialog = false;
									this.$message.success("登录成功")
									this.$emit("loginSuccess")
									location.reload();
								}, 1500)
							}
						})
					}
				})

			}
		}
	};
</script>


<style lang="scss" scoped>
	$bg:#283443;
	$light_gray:#FFF;
	$cursor:#000;
	$bg1:#2d3a4b;
	$light_gray1:#eee;
	$dark_gray:#889aa4;


	@supports (-webkit-mask:none) and (not (cater-color:$cursor)) {
		.login-container .el-input input {
			color: $cursor;
		}
	}

	/deep/.el-form-item {
		border: 1px solid rgba(255, 255, 255, 0.1);
		background: rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		color: #454545;
	}

	.login-form {
		.title-container {
			position: relative;

			.title {
				font-size: 26px;
				color: #000000;
				margin: 0px auto 40px auto;
				text-align: center;
				font-weight: bold;
			}
		}

		.svg-container {
			// padding: 6px 5px 6px 15px;
			color: $dark_gray;
			font-size: 18px;
			line-height: 47px;
			margin-left: 10px;
			// vertical-align: middle;
			width: 47px;
			// display: inline-block;
		}


		/deep/.el-input {
			display: inline-block;
			height: 47px;
			width: 300px;
			margin-left: 10px;

			input {
				background: transparent;
				border: none;
				-webkit-appearance: none;
				border-radius: 10px;
				// padding: 12px 5px 12px 15px;
				color: #000000;
				height: 47px;
				caret-color: $cursor;

				// &:-webkit-autofill {
				// 	box-shadow: 0 0 0px 1000px #cccccc inset !important;
				// 	-webkit-text-fill-color: $cursor !important;
				// }
			}
		}


		// height: 320px;
		// 	z-index: 999;
		// 	position: absolute;
		// 	width: 520px;
		// 	max-width: 100%;
		// 	top: 50%;
		// 	margin-top: -160px;
		// 	padding-top: 20px;
		// 	overflow: hidden;
		// 	transition: 0.5s;

	}


	/deep/.el-dialog__header {
		display: none !important
	}

	/deep/.el-dialog__body {
		// background-image: url(../../../assets/carousel/2.png);
		// background-color: rgba(255, 255, 255, 1);
		// background-color: #cccccc;
	}

	/deep/.el-dialog__body::before {
		content: '';
		display: block;
		/*width: 100%;*/
		/*height: 100%;*/
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		/*background-image: url(../assets/carousel/success400.png);*/
		opacity: 0.3;
	}


	.code {
		/*margin: 400px auto;*/
		width: 114px;
		height: 40px;
		/*border: 1px solid red;*/
	}
</style>
